
const getGallery = (user) => {
  const picturesArray = [];
  user.pictures?.map( picture  => {
    return picture.image.map( image  => {
      picturesArray.push({
        localFile: {
          childImageSharp: {
            ...image.localFile.childImageSharp,
            thumbAlt: image.alternativeText,
            title: picture.title,
            caption: image.description
          }
        }
      });
      return image;
    })
  })
  return picturesArray;
}

export {getGallery} ;