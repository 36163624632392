import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import Header from "../../components/ui/base/layout/header"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import TourCard from "../../components/ui/base/TourCard"
import classnames from "classnames"
import * as styles from "./index.module.scss"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import Section from "../../components/ui/base/Section"
import PhotoGallery from "../../components/ui/base/Gallery"
import { getGallery } from "../../util/photographer"
import { createMarkup, removeDuplicates } from "../../util/tour"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useTranslation } from "react-i18next"
import { BASE_URL, PHOTOGRAPHERS_URL } from "../../util/constants"

const PhotographerPage = ({ data }) => {
  const { i18n: l  } = useTranslation();
  const photographer = data.strapiAllUsers;
  const userPictures = getGallery(photographer);
  const tours = removeDuplicates(data?.allStrapiTour.edges);
  const photographerLink = `${BASE_URL[l.language]}/${PHOTOGRAPHERS_URL[l.language]}/${photographer.username}`


  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang : l.language }}
        title={`${photographer.firstName} ${photographer.lastName}`}
        language={l.language}
        description={photographer.bio}
        canonical={photographerLink}
      />
      <Header
        img={photographer.cover?.localFile.childImageSharp.gatsbyImageData}
        theme="dark"
        size={"big"}
      >

        <Container fluid="xl">
          <Row className="align-items-center pt-5">
            <Col lg={12}>
              <div className="intro_title text-center">

                <div className={classnames([styles.profilePicture])}>
                  {photographer.profilePicture?.localFile &&
                  <GatsbyImage  alt={`Fotografo ${photographer.firstName} ${photographer.lastName}`} image={photographer.profilePicture.localFile.childImageSharp.gatsbyImageData}/>
                  }
                </div>


                <Typography className="animated fadeInDown" variant="heading1">
                  <span className="weshoot"> {photographer.firstName}</span> {photographer.lastName}
                </Typography>
                <Typography className="animated fadeInDown" variant="heading4">
                  <div dangerouslySetInnerHTML={createMarkup(photographer.bio)}/>
                </Typography>
                <Breadcrumbs elements={[
                  {
                    name: "WeShoot",
                    path: "/",
                  },
                  {
                    name: "Fotografi",
                    path: "/fotografi/",
                  },
                  {
                    name: `${photographer.firstName} ${photographer.lastName}`,
                    path: `/fotografi/${photographer.username}`,
                  }
                ]} />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      {userPictures.length > 0 && <Section
        title={"Alcune delle mie ultime fotografie scattate nei miei viaggi"}
        subtitle={""}>
        <div className={classnames([styles.gallery])}>
          <PhotoGallery data={userPictures}/>
        </div>

      </Section>}

      <Section
        className={classnames([styles.myTrips])}
        variant={"grey"}
        title={"Parti in viaggio con me"}
        subtitle={"Questi sono i viaggi fotografici dove ti faró da coach"}>
        <Row>
          {tours.map(tour => {
            return (
              <Col lg={4} md={6} className="zoomIn mb-2" key={tour.id}>
                <TourCard tour={tour.node}/>
              </Col>
            )
          })
          }
        </Row>
      </Section>


    </>
  )
}

export default PhotographerPage

export const query = graphql`
  query PhotographerQuery($username: String!) {
  strapiAllUsers(username: {eq: $username}) {
    firstName
    username
    instagram
    lastName
    bio
     cover {
      alternativeText
      localFile {
          ...ImageFragment
        }
    }
     profilePicture {
      alternativeText
      localFile {
          ...ImageFragment
        }
    }
    pictures {
      tour
      title
      image {
        localFile {
          ...ImageFragment
        }
        alternativeText
        caption
      }
    }
    reviews {
      title
      rating
      tour
    }
    sessions {
      status
      tour
    }
  }
  allStrapiTour(
    filter: {sessions: {elemMatch: {users: {elemMatch: {username: {eq: $username}}}}}}
  ) {
    ...StrapiTourConnectionFragment
  }
}
fragment StrapiTourConnectionFragment on StrapiTourConnection {
  edges {
    node {
      id
      image {
        url
        localFile {
          ...ImageFragment
        }
      }
      slug
      states {
        slug
      }
      places {
        slug
      }
      sessions {
        end
        id
        sessionId
        start
        status
        maxPax
        balance
        price
        deposit
        currency
        users {
          id
          firstName
          instagram
          profilePicture {
            localFile {
              ...ImageFragment
            }
          }
        }
      }
      excerpt
      experienceLevel
      title
    }
  }
}

`


